function fetchGraphQLData(url, query) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  const body = JSON.stringify({ query });

  return fetch(url, {
    method: "POST",
    headers: headers,
    body: body,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .catch((error) => {
      throw new Error(`Error in GraphQL query: ${error.message}`);
    });
}
function initTabsAndCarousel() {
  document.querySelectorAll('.home-carousel-container').forEach(container => {
    const nextBtn = container.querySelector('.home-carousel-next');
    const prevBtn = container.querySelector('.home-carousel-prev');
    const carousel = container.querySelector('.home-process-cards-list');

    if (nextBtn && carousel) {
      nextBtn.addEventListener('click', () => {
        carousel.scrollLeft += carousel.offsetWidth;
      });
    }

    if (prevBtn && carousel) {
      prevBtn.addEventListener('click', () => {
        carousel.scrollLeft -= carousel.offsetWidth;
      });
    }
  });

  const tabButtons = document.querySelectorAll('.home-process-tabs div');
  const tabContents = document.querySelectorAll('.home-process-tab-content');

  if (tabButtons.length > 0 && tabContents.length > 0) {
    tabButtons.forEach(button => {
      button.addEventListener('click', () => {
        tabButtons.forEach(btn => btn.classList.remove('active'));
        button.classList.add('active');
        tabContents.forEach(content => content.classList.remove('active'));
        const tabId = button.getAttribute('data-tab');
        const targetTabContent = document.getElementById(tabId);
        if (targetTabContent) {
          targetTabContent.classList.add('active');
        }
      });
    });
  }
}

function renderParticipatoryProcessTypes(apiData) {
  if (
    !apiData ||
    !apiData.data ||
    !apiData.data.participatoryProcessTypes ||
    apiData.data.participatoryProcessTypes.length === 0
  ) {
    return `
      <section id="participatory-process-section" class="home-sections-container">
        <span class="process-section-title">Processos em Aberto</span>
        <div class="page-process-error">
          <span>Nenhum processo encontrado.</span>
        </div>
      </section>
    `;
  }

  const processTypes = apiData.data.participatoryProcessTypes;

  let tabHeadersHTML = `<div class="home-process-tabs">`;
  let tabContentHTML = ``;

  processTypes.forEach((type, index) => {
    const tabId = `home-tab-${index}`;
    tabHeadersHTML += `<div class="${index === 0 ? "active" : ""}" data-tab="${tabId}">${
      type.title.translation
    }</div>`;

    const validProcesses = type.processes.filter((process) => process);

    const cardsHTML =
      validProcesses.length > 0
        ? validProcesses
            .map((process) => {
              const imageUrl = process.heroImage || "verImagemPadraoComEquipeDeDesign";
              const title = process.title.translation;
              const votes = process.votes || 0;
              const comments = process.comments || 0;
              return `
                <div class="home-process-card">
                  <img src="${imageUrl}" alt="Imagem do processo ${title}">
                  <div class="home-process-card-content">
                    <a href="/processes/${process.slug}">${title}</a>
                    <div class="home-process-card-footer">
                      <span class="home-process-tags">${process.hashtag || "sem tags"}</span>
                      <div class="home-process-card-stats">
                        <span class="votes">${votes} votos</span>
                        <span class="total-comments">${comments} comentários</span>
                      </div>
                    </div>
                  </div>
                </div>
              `;
            })
            .join("\n")
        : `<span class="process-section-error">Nenhum processo encontrado para este tipo.</span>`;

    tabContentHTML += `
      <div id="${tabId}" class="home-process-tab-content ${index === 0 ? "active" : ""}">
        <div class="home-carousel-container">
          <div class="home-carousel-nav">
            <button class="home-carousel-prev" aria-label="Voltar">
              <i class="fa-solid fa-chevron-left"></i>
            </button>
            <button class="home-carousel-next" aria-label="Avançar">
              <i class="fa-solid fa-chevron-right"></i>
            </button>
          </div>
          <div class="home-carousel-wrapper">
            <div class="home-process-cards-list">
              ${cardsHTML}
            </div>
          </div>
        </div>
        <br>
      </div>
    `;
  });

  tabHeadersHTML += `</div>`;

  return `
    <section id="participatory-process-section" class="home-sections-container">
      <span class="process-section-title">Processos em Aberto</span>
      <span class="process-section-description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pellentesque sem 
        justo, eleifend tempor ipsum tempor sit amet. Curabitur ut tempor nisi, eu facilisis 
        turpis. Suspendisse interdum nec mi non commodo. Donec in nisl vulputate, consequat 
        erat a, efficitur tellus. Suspendisse viverra risus sit amet vehicula scelerisque.
      </span>
      <div class="home-processes">
        ${tabHeadersHTML}
        ${tabContentHTML}
        <a href="/processes" class="br-button primary">Ver mais</a>
      </div>
    </section>
  `;
}

function manageParticipatoryProcesses(apiUrl) {
  const container = document.querySelector("#participatory-process-section");
  if (!container) {
    console.error('Elemento com id "participatory-process-section" não encontrado.');
    return;
  }

  container.innerHTML = `
    <section class="home-sections-container">
      <span class="process-section-title">Processos em Aberto</span>
      <div class="page-process-error">
        <div class="home-processes">
          <span class="process-loading">Carregando...</span>
        </div>
      </div>
    </section>
  `;

  const query = `
    query {
      participatoryProcessTypes {
        title {
          translation(locale: "pt-BR")
        }
        processes {
          id
          slug
          publishedAt
          title {
            translation(locale: "pt-BR")
          }
          heroImage
          hashtag
          votes
          comments
        }
      }
    }
  `;

  fetchGraphQLData(apiUrl, query)
    .then((apiData) => {
      apiData.data.participatoryProcessTypes.sort((a, b) => {
        const titleA = a.title.translation.toLowerCase();
        const titleB = b.title.translation.toLowerCase();
        return titleA.localeCompare(titleB);
      });

      const renderedHTML = renderParticipatoryProcessTypes(apiData);
      container.innerHTML = renderedHTML;
      initTabsAndCarousel();
    })
    .catch((error) => {
      console.error("Erro:", error);
      container.innerHTML = `
        <section class="home-sections-container">
          <span class="process-section-title">Processos em Aberto</span>
          <div class="page-process-error">
            <div class="home-processes">
              <span>Erro ao buscar processos.</span>
            </div>
          </div>
        </section>
      `;
    });
}

window.manageParticipatoryProcesses = {
  manageParticipatoryProcesses: manageParticipatoryProcesses,
};
